export default ($axios) => ({
  async getAllPullNumberIg() {
    try {
      const { data } = await $axios.get("pull-ig/all");
      return data;
    } catch (e) {
      return e;
    }
  },
  async getPullNumberIg() {
    try {
      const { data } = await $axios.get("pull-ig");
      return data;
    } catch (e) {
      return e;
    }
  },
  async postPullNumberIg({ user_id, ig_user_id, weight, link }) {
    try {
      const { data } = await $axios.post("pull-ig/create", {
        user_id,
        ig_user_id,
        weight,
        link,
      });
      return data;
    } catch (e) {
      return e;
    }
  },
  async getPullNumberIdIg(id) {
    try {
      const { data } = await $axios.get("pull-ig", { params: { id } });
      return data;
    } catch (e) {
      return e;
    }
  },
  async putPullNumberIg({ user_id, predict }) {
    try {
      const { data } = await $axios.put("pull-ig", {
        user_id,
        vk_user_id: predict,
      });
      return data;
    } catch (e) {
      return e;
    }
  },
  async deletePullNumberIdIG(id) {
    try {
      const { data } = await $axios.delete(`pull-ig/${id}`);
      return data;
    } catch (e) {
      return e;
    }
  },
});

import { redirectToHomeByDontAdmin } from "@/router/_helper";
import { api } from "@/api";
import store from "@/store";
import Toast from "@/plugins/toast";

export const routes = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Home.vue"),
    meta: {
      layout: "DefaultLayout",
      title: "MyRando – сервис для генерации случайных чисел",
      metaTags: [
        {
          name: "description",
          content:
            "MyRando – простой и понятный рандомайзер чисел. Наши бесплатные онлайн инструменты облегчат вам работу и сэкономят много времени",
        },
        {
          name: "og:title",
          content: "MyRando – сервис для генерации случайных чисел",
        },
        {
          property: "og:description",
          content:
            "MyRando – простой и понятный рандомайзер чисел. Наши бесплатные онлайн инструменты облегчат вам работу и сэкономят много времени",
        },
      ],
    },
  },
  {
    path: "/number",
    name: "Number",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Number.vue"),
    meta: {
      layout: "DefaultLayout",
      title: "Генератор случайных чисел онлайн | MyRando",
      metaTags: [
        {
          name: "description",
          content:
            "MyRando это бесплатный онлайн генератор случайных чисел в нужном заданном интервале. Вы можете сгенерировать сколько угодно значений - 1, 3, 5,10 или больше случайных чисел",
        },
        {
          name: "og:title",
          content: "Генератор случайных чисел онлайн | MyRando",
        },
        {
          property: "og:description",
          content:
            "MyRando это бесплатный онлайн генератор случайных чисел в нужном заданном интервале. Вы можете сгенерировать сколько угодно значений - 1, 3, 5,10 или больше случайных чисел",
        },
      ],
    },
  },
  {
    path: "/ask",
    name: "Ask",
    component: () => import(/* webpackChunkName: "about" */ "../views/Ask.vue"),
    meta: {
      layout: "DefaultLayout",
      title: "Вопрос магическому шару | MyRando",
      metaTags: [
        {
          name: "description",
          content:
            "Использовать волшебный шар удобно, а сама форма шара мистически загадочна. Воспользуйтесь сервисом с вопросом магическому шару",
        },
        { name: "og:title", content: "Вопрос магическому шару | MyRando" },
        {
          property: "og:description",
          content:
            "Использовать волшебный шар удобно, а сама форма шара мистически загадочна. Воспользуйтесь сервисом с вопросом магическому шару",
        },
      ],
    },
  },
  {
    path: "/password",
    name: "Password",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Password.vue"),
    meta: {
      layout: "DefaultLayout",
      title: "Генератор паролей онлайн | MyRando",
      metaTags: [
        {
          name: "description",
          content:
            "Генератор паролей MyRando – это универсальное средство для онлайн генерации качественного и безопасного, устойчивого к взлому и легко запоминающегося пароля по выбранным параметрам с использованием различных символов",
        },
        { name: "og:title", content: "Генератор паролей онлайн | MyRando" },
        {
          property: "og:description",
          content:
            "Генератор паролей MyRando – это универсальное средство для онлайн генерации качественного и безопасного, устойчивого к взлому и легко запоминающегося пароля по выбранным параметрам с использованием различных символов",
        },
      ],
    },
  },
  {
    path: "/question",
    name: "Question",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Question.vue"),
    meta: {
      layout: "DefaultLayout",
      title: "Вопросы и ответы | MyRando",
      metaTags: [
        { name: "description", content: "" },
        {
          name: "og:title",
          content: "Вопросы и ответы | MyRando",
        },
        { property: "og:description", content: "" },
      ],
    },
    beforeEnter: async (to, from, next) => {
      await Toast.ToastForAxios("Страница в разработке");
      await next({ name: from.name });
    },
  },
  {
    path: "/fact",
    name: "Fact",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Fact.vue"),
    meta: {
      layout: "DefaultLayout",
      title: "Случайный факт дня | MyRando",
      metaTags: [
        {
          name: "description",
          content:
            "Перед вами онлайн генератор интересных фактов о людях, животных, работе, городах и многом другом. Удивительные факты со всех уголков планеты",
        },
        { name: "og:title", content: "Случайный факт дня | MyRando" },
        {
          property: "og:description",
          content:
            "Перед вами онлайн генератор интересных фактов о людях, животных, работе, городах и многом другом. Удивительные факты со всех уголков планеты",
        },
      ],
    },
  },
  {
    path: "/win",
    name: "Win",
    component: () => import(/* webpackChunkName: "about" */ "../views/Win.vue"),
    meta: {
      layout: "DefaultLayout",
      title: "Определение победителей ВКонтакте и Instagram | MyRando",
      metaTags: [
        {
          name: "description",
          content:
            "Независимый сервис для определения случайных победителей розыгрышей Вконтакте. Выбор как по репостам записей, так и по лайкам.",
        },
        {
          name: "og:title",
          content: "Определение победителей ВКонтакте и Instagram | MyRando",
        },
        {
          property: "og:description",
          content:
            "Независимый сервис для определения случайных победителей розыгрышей Вконтакте. Выбор как по репостам записей, так и по лайкам.",
        },
      ],
    },
  },
  {
    path: "/win/instagram",
    name: "InstagramWinner",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/InstagramWinner.vue"),
    meta: {
      layout: "DefaultLayout",
      title: "Определение победителей Instagram | MyRando",
      metaTags: [
        {
          name: "description",
          content:
            "Независимый сервис для определения случайных победителей розыгрышей Вконтакте. Выбор как по репостам записей, так и по лайкам.",
        },
        {
          name: "og:title",
          content: "Определение победителей ВКонтакте и Instagram | MyRando",
        },
        {
          property: "og:description",
          content:
            "Независимый сервис для определения случайных победителей розыгрышей Вконтакте. Выбор как по репостам записей, так и по лайкам.",
        },
      ],
    },
  },
  {
    path: "/personal_cabinet",
    name: "Personal",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Personal.vue"),
    meta: {
      layout: "DefaultLayout",
      title: "Личный кабинет | MyRando",
      metaTags: [
        { name: "description", content: "" },
        {
          name: "og:title",
          content: "Личный кабинет | MyRando",
        },
        { property: "og:description", content: "" },
      ],
    },
  },
  {
    path: "/admin",
    name: "Admin",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Admin.vue"),
    meta: {
      layout: "DefaultLayout",
      title: "Панель администрирования | MyRando",
      metaTags: [
        { name: "description", content: "" },
        {
          name: "og:title",
          content: "Панель администрирования | MyRando",
        },
        { property: "og:description", content: "" },
      ],
    },
    beforeEnter: [redirectToHomeByDontAdmin],
  },
  {
    path: "/auth/vk",
    name: "AuthVk",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Home.vue"),
    meta: {
      layout: "DefaultLayout",
      title: "Авторизация VK | MyRando",
      metaTags: [
        { name: "description", content: "Авторизация VK | MyRando." },
        {
          name: "og:title",
          content: "Авторизация VK | MyRando",
        },
        { property: "og:description", content: "Авторизация VK | MyRando." },
      ],
    },
    beforeEnter: async (to, from, next) => {
      await api.auth.postAuthVk(to.query.code);
      await store.dispatch("GET_USER_ME_VK");
      await next({ name: "Home" });
    },
  },
  {
    path: "/auth/insta",
    name: "AuthIg",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Home.vue"),
    meta: {
      layout: "DefaultLayout",
      title: "Авторизация Instagram | MyRando",
      metaTags: [
        { name: "description", content: "" },
        {
          name: "og:title",
          content: "Авторизация Instagram | MyRando.",
        },
        { property: "og:description", content: "" },
      ],
    },
    beforeEnter: async (to, from, next) => {
      await api.auth.postAuthIg(to.query.code);
      await store.dispatch("GET_USER_ME_IG");
      await next({ name: "Home" });
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import("../views/Home.vue"),
    meta: {
      layout: "DefaultLayout",
      title: "404 | MyRando",
      metaTags: [
        { name: "description", content: "" },
        {
          name: "og:title",
          content: "404 | MyRando",
        },
        { property: "og:description", content: "" },
      ],
    },
    beforeEnter(to, from, next) {
      return next({ name: "Home" });
    },
  },
];

// {
//   path: "/offer",
//       name: "Offer",
//     component: () =>
//     import(/* webpackChunkName: "about" */ "../views/Offer.vue"),
//     meta: {
//   layout: "DefaultLayout",
//       title: "Правила и Условия | MyRando",
//       metaTags: [
//     { name: "description", content: "" },
//     {
//       name: "og:title",
//       content: "Правила и Условия | MyRando",
//     },
//     { property: "og:description", content: "" },
//   ],
// },
// },

export default {
  state: {
    desktop: true,
  },
  mutations: {
    UPDATE__WIDTH(state, boolean) {
      state.desktop = boolean;
    },
  },
  actions: {
    CHECK__WIDTH({ commit }, boolean) {
      commit("UPDATE__WIDTH", boolean);
    },
  },
  getters: {
    DESKTOP(state) {
      return state.desktop;
    },
  },
};

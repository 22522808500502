<template>
  <label :for="name">
    <span
      v-if="!label"
      class="label text__gray3 text__12"
      :class="{
        error: !!errorMessage,
        success: meta.valid && !disabled && meta.dirty,
      }"
      >{{ placeholder }}</span
    >
    <Field
      :name="name"
      :id="name"
      :type="type"
      :placeholder="placeholder"
      v-maska="maska"
      class="text__14 text__black"
      :class="{
        error: !!errorMessage,
        success: meta.valid && meta.touched,
        disabled: disabled,
      }"
      @input="$emit('eventInput', $event.target.value)"
    />
    <ErrorMessage class="label__error text__12 text__red" :name="name" />
  </label>
</template>

<script>
import { useField } from "vee-validate";
const defaultSetup = {
  type: String,
  required: true,
};
const defaultBol = {
  type: Boolean,
  default: false,
};

export default {
  name: "IBase",
  emits: ["eventInput"],
  props: {
    name: defaultSetup,
    type: defaultSetup,
    placeholder: defaultSetup,
    maska: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    disabled: defaultBol,
    error: defaultBol,
    label: defaultBol,
  },
  setup(props) {
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(props.name, undefined, {
      initialValue: props.value,
    });

    return {
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
    };
  },
};
</script>

<style lang="scss" scoped>
label,
.label {
  display: block;
}

label {
  position: relative;
  height: 100%;
  .label {
    margin: 0 0 4px;
    &.success {
      color: $blue_d1;
    }
    &.error {
      color: $red;
    }
  }

  input[type="text"] {
    height: 46px;
    padding: 12px 14px;
    border: 1px solid $lilac_l1;
    border-radius: 8px;
    width: 100%;
    &.success {
      border: 1px solid $blue_d1;
    }
    &.error {
      border: 1px solid $red;
    }
  }
  .label__error {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
  }
}
</style>

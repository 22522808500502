import axios from "axios";
import store from "@/store";
// import { api } from "@/api";
import Toast from "@/plugins/toast";

export const $axios = axios.create();
// const store = require("./../store");
$axios.defaults.baseURL = process.env.VUE_APP_BASE_URL_API;
const token = localStorage.getItem("token");
if (token) $axios.defaults.headers.common["Authorization"] = "Bearer " + token;

// // Add a request interceptor
// $axios.interceptors.request.use(
//   function (config) {
//     return config;
//   },
//   function (error) {
//     return Promise.reject(error);
//   }
// );

// Add a response interceptor
$axios.interceptors.response.use(
  function (response) {
    // if (response.data.access_token) {
    //   $axios.defaults.headers.common[
    //     "Authorization"
    //   ] = `Bearer ${response.data.access_token}`;
    // }
    return response;
  },
  async function (error) {
    const res = error.response;
    if (res) {
      // Toast.ToastForAxios(error.response);
      /*if (
        (res.status === 401 && res.data.message === "VK auth failure") ||
        (res.status === 401 &&
          res.data.message === "You are not allowed to access this page") ||
        res.status === 401
      ) {
        localStorage.removeItem("token");
      }*/
      if (res.status === 401) {
        Toast.ToastForAxios("Ваша сессия истекла.");
        await store.dispatch("LOGOUT");
        await store.dispatch("SHOW_OR_HIDE_MODAL_LOGIN", { boolean: true });
      } else {
        Toast.ToastForAxios("Ошибка: " + res.message);
      }
    }
    return error;
  }
);

export default { $axios };

import { setLocale } from "yup";
import * as Yup from "yup";
import { globalYupMethods } from "@/plugins/yup/methods";
const ru = require("@/lang/ru.json");

setLocale(ru.messages_yup);
globalYupMethods(ru.custom_yup);

export const globalYup = (app) => {
  app.config.globalProperties.$yup = Yup;
};

export default ($axios) => ({
  async getPullNumber() {
    try {
      const { data } = await $axios.get("pull-vk");
      return data;
    } catch (e) {
      return e;
    }
  },
  async postPullNumber({ user_id, vk_user_id, weight }) {
    try {
      const { data } = await $axios.post("pull-vk", {
        user_id,
        vk_user_id,
        weight,
      });
      return data;
    } catch (e) {
      return e;
    }
  },
  async getPullNumberId(id) {
    try {
      const { data } = await $axios.get("pull-vk", { params: { id } });
      return data;
    } catch (e) {
      return e;
    }
  },
  async putPullNumber({ user_id, predict }) {
    try {
      const { data } = await $axios.put("pull-vk", {
        user_id,
        vk_user_id: predict,
      });
      return data;
    } catch (e) {
      return e;
    }
  },
  async deletePullNumberId(id) {
    try {
      const { data } = await $axios.delete(`pull-vk/${id}`);
      return data;
    } catch (e) {
      return e;
    }
  },
});

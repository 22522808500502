<template>
  <TheHeader />
  <main>
    <router-view />
  </main>
  <TheFooter />
  <MLogin v-if="MODAL_LOGIN" @close="SHOW_OR_HIDE_MODAL_LOGIN(false)" />
</template>

<script>
import TheFooter from "@/components/TheFooter";
import TheHeader from "@/components/header/TheHeader";
import MLogin from "@/components/ui/modal/MLogin";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { TheFooter, TheHeader, MLogin },
  created() {
    this.CHECK__AUTH();
  },
  computed: { ...mapGetters(["MODAL_LOGIN"]) },
  methods: {
    ...mapActions(["SHOW_OR_HIDE_MODAL_LOGIN", "LOGIN", "CHECK__AUTH"]),
  },
};
</script>

<style lang="scss">
.header__content,
main,
.footer__content {
  max-width: 1212px;
  margin: 0 auto;
  width: 100%;
}

main {
  padding: 50px 16px;
  @media screen and (max-width: 1920px) {
    @include adaptive-value("padding", 50, 16, 50, 16);
  }
}
</style>

import { addMethod, string } from "yup";
// import * as Yup from "yup";

export const globalYupMethods = (messages) => {
  // addMethod(string, "minPassword", (arg = 6) => {
  //   return string().test(
  //     "",
  //     `Пароль должен содержать от ${arg} символов`,
  //     (value) => value.length && value.length >= arg
  //   );
  // });
  //
  // addMethod(string, "confirmPassword", (key) => {
  //   return string()
  //     .required()
  //     .oneOf([Yup.ref(key)], messages.confirmPassword);
  // });
  //
  addMethod(string, "phone", () => {
    return string().required().min(16, messages.phone);
  });
};

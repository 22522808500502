import { createStore } from "vuex";
import auth from "./_auth";
import desktop from "@/store/_desktop";
import modal_login from "@/store/_modalLogin";
import user from "@/store/_user";

export default createStore({
  modules: {
    auth,
    desktop,
    modal_login,
    user,
  },
});

export default ($axios) => ({
  async getUser() {
    try {
      const { data } = await $axios.get("user");
      return data;
    } catch (e) {
      return e;
    }
  },
  async getUserId(id) {
    try {
      const { data } = await $axios.get("user", { params: { id } });
      return data;
    } catch (e) {
      return e;
    }
  },
  async getUserMe() {
    try {
      const { data } = await $axios.get("user/me");
      return data;
    } catch (e) {
      return e;
    }
  },
  async getUserMeVk() {
    try {
      const { data } = await $axios.get("user/me-vk");
      return data;
    } catch (e) {
      return e;
    }
  },
  async getUserMeIg() {
    try {
      const { data } = await $axios.get("user/me-ig");
      return data;
    } catch (e) {
      return e;
    }
  },
});

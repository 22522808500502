export default {
  data() {
    return {
      hideMenu: {
        one: true,
        showElse: false,
      },
    };
  },
  mounted() {
    this.handlerHideMenu();
    window.addEventListener("resize", this.handlerHideMenu);
  },
  unmounted() {
    window.removeEventListener("resize", this.handlerHideMenu);
  },
  methods: {
    handlerHideMenu() {
      if (window.innerWidth <= 1110) {
        this.hideMenu.showElse = true;
        this.hideMenu.one = false;
      } else {
        this.hideMenu.showElse = false;
        this.hideMenu.one = true;
      }
    },
  },
};

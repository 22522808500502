export default ($axios) => ({
  async getPullNumber() {
    try {
      const { data } = await $axios.get("pull-number");
      return data;
    } catch (e) {
      return e;
    }
  },
  async postPullNumber({ user_id, predict }) {
    try {
      const { data } = await $axios.post("pull-number", { user_id, predict });
      return data;
    } catch (e) {
      return e;
    }
  },
  async getPullNumberId(id) {
    try {
      const { data } = await $axios.get("pull-number", { params: { id } });
      return data;
    } catch (e) {
      return e;
    }
  },
  async putPullNumber({ user_id, predict }) {
    try {
      const { data } = await $axios.put("pull-number", { user_id, predict });
      return data;
    } catch (e) {
      return e;
    }
  },
  async deletePullNumberId(id) {
    try {
      const { data } = await $axios.delete(`pull-number/${id}`);
      return data;
    } catch (e) {
      return e;
    }
  },
});

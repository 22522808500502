<template>
  <button class="text__button">{{ name }}</button>
</template>

<script>
export default {
  name: "ButtonPurple",
  props: {
    name: { type: String, required: true },
  },
};
</script>

<style lang="scss" scoped>
button {
  position: relative;
  border-radius: 6px;
  padding: 13px 24px;
  height: 46px;
  transition: 0.1s ease-in;
  &.disabled {
    opacity: 0.5;
  }
  background: $purpul;
  color: white;
  max-width: 310px;

  &:hover:enabled {
    background: $purpul_l1;
  }

  &:active:enabled {
    background: $purpul_d1;
  }
}
</style>

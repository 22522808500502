<template>
  <header>
    <div class="header__content">
      <router-link :to="{ name: 'Home' }" class="header__content__logo">
        <img
          style="width: 36px"
          src="@/assets/images/svg/logo-header.svg"
          alt="logo"
        />
        <span class="text__h2 adaptive text__black">My Rando</span>
      </router-link>
      <nav v-if="DESKTOP" class="header__content__nav text__20">
        <router-link class="text__black" :to="{ name: 'Number' }"
          >Генератор чисел</router-link
        >
        <router-link class="text__black" :to="{ name: 'Win' }">
          Победитель VK
        </router-link>
        <router-link class="text__black" :to="{ name: 'InstagramWinner' }">
          Победитель IG
        </router-link>
        <router-link class="text__black" :to="{ name: 'Password' }">
          Пароли
        </router-link>
        <router-link class="text__black" :to="{ name: 'Ask' }">
          Ответы и предсказания
        </router-link>
        <router-link
          v-if="hideMenu.one"
          class="text__black"
          :to="{ name: 'Question' }"
        >
          Вопросы
        </router-link>
        <router-link
          v-if="hideMenu.one"
          class="text__black"
          :to="{ name: 'Fact' }"
        >
          Факты
        </router-link>
        <div class="button__else" v-if="hideMenu.showElse">
          <span class="button__else__title">Еще</span>
          <div class="button__else__menu">
            <router-link
              v-if="!hideMenu.one"
              class="text__black"
              :to="{ name: 'Question' }"
            >
              Вопросы
            </router-link>
            <router-link
              v-if="!hideMenu.one"
              class="text__black"
              :to="{ name: 'Fact' }"
            >
              Факты
            </router-link>
          </div>
        </div>
      </nav>
      <HPersonal v-if="DESKTOP" />
      <button
        v-if="!DESKTOP"
        class="header__burger"
        :class="{ active: showBurger }"
        @click="showBurger = !showBurger"
      >
        <span></span>
      </button>
    </div>
    <div v-if="showBurger && !DESKTOP" class="burger__menu">
      <router-link class="text__black" :to="{ name: 'Number' }"
        >Генератор чисел</router-link
      >
      <router-link class="text__black" :to="{ name: 'Win' }">
        Победитель VK
      </router-link>
      <router-link class="text__black" :to="{ name: 'InstagramWinner' }">
        Победитель IG
      </router-link>
      <router-link class="text__black" :to="{ name: 'Password' }">
        Пароли
      </router-link>
      <router-link class="text__black" :to="{ name: 'Ask' }">
        Ответы и предсказания
      </router-link>
      <router-link class="text__black" :to="{ name: 'Question' }">
        Вопросы
      </router-link>
      <router-link class="text__black" :to="{ name: 'Fact' }">
        Факты
      </router-link>
      <HPersonal class="h-personal" />
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
import resizeHideNavHeader from "@/mixins/resizeHideNavHeader";
import HPersonal from "@/components/header/HPersonal";

export default {
  name: "TheHeader",
  components: { HPersonal },
  mixins: [resizeHideNavHeader],
  data() {
    return {
      showBurger: false,
    };
  },
  watch: {
    $route() {
      this.showBurger = false;
    },
  },
  computed: {
    ...mapGetters(["DESKTOP"]),
  },
};
</script>

<style lang="scss" scoped>
header {
  background: #ffffff;
  box-shadow: 0 1px 20px rgba(132, 134, 176, 0.1);
  width: 100%;
}
.header__content {
  width: 100%;
  @include disflex(flex, space-between, center);
  gap: 20px;
  padding: 16px;
  &__logo {
    @include disflex(flex, stretch, center);
    gap: 8px;
  }
  &__nav {
    @include disflex(flex, stretch, center);
    flex: 1 0 auto;
    gap: 16px;

    a {
      position: relative;
      &:after {
        position: absolute;
        top: calc(100% + 4px);
        content: "";
        left: 0;
        width: 100%;
        height: 1px;
        background: $lilac;
        opacity: 0;
        transition: 0.1s ease-in;
      }
    }

    a.router-link-active {
      &:after {
        opacity: 1;
      }
    }
  }
}

.header__burger {
  position: relative;
  width: 24px;
  height: 24px;
  @include disflex(flex, center, center);
  &.active {
    span {
      transform: scale(0);
    }
    &:before {
      transform: rotate(45deg);
      top: 11px;
    }

    &:after {
      transform: rotate(-45deg);
      bottom: 11px;
    }
  }

  span,
  &:before,
  &:after {
    background: $gray4;
    border-radius: 16px;
    width: 16px;
    height: 2px;
    transition: all 0.3s ease 0s;
  }

  span {
    display: block;
  }

  &:before,
  &:after {
    position: absolute;
    content: "";
  }

  &:before {
    top: 7px;
  }

  &:after {
    bottom: 7px;
  }
}

.burger__menu {
  padding: 12px;
  a,
  .h-personal {
    display: block;
    padding: 12px;
  }
}

.button__else {
  position: relative;
  &:hover {
    .button__else__menu {
      display: block !important;
    }
  }
  &__title {
  }

  &__menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    padding: 12px;
    background: white;
    box-shadow: 0 1px 20px rgba(132, 134, 176, 0.1);
    z-index: 2;
    border-radius: 0 0 8px 8px;
    a {
      display: block;
      margin: 0 0 12px;
      &:last-child {
        margin: 0;
      }
    }
  }
}
</style>

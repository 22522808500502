import { $axios } from "@/plugins/axios";

export default {
  actions: {
    LOGIN({ commit, dispatch }, token) {
      $axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      localStorage.setItem("token", token);
      dispatch("GET_USER_ME");
      commit("UPDATE_STATE_AUTH", true);
    },
    async LOGOUT({ commit }) {
      $axios.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      return commit("UPDATE_STATE_AUTH", false);
    },
    CHECK__AUTH({ commit, dispatch }) {
      // dispatch("GET_USER_ME_IG"))
      localStorage.getItem("token")
        ? (commit("UPDATE_STATE_AUTH", !0),
          dispatch("GET_USER_ME"),
          dispatch("GET_USER_ME_VK"))
        : (($axios.defaults.headers.common["Authorization"] = ""),
          localStorage.removeItem("user"),
          commit("UPDATE_STATE_AUTH", !1));
      /*if (localStorage.getItem("token")) {
              $axios.defaults.headers.common["Authorization"] =
                "Bearer " + localStorage.getItem("token");
              commit("UPDATE_STATE_AUTH", true);
              dispatch("GET_USER_ME");
            } else {
              $axios.defaults.headers.common["Authorization"] = "";
              localStorage.removeItem("user");
              commit("UPDATE_STATE_AUTH", false);
            }*/
    },
  },
  mutations: {
    UPDATE_STATE_AUTH(state, boolean) {
      state.auth = boolean;
    },
  },
  state: {
    auth: false,
  },
  getters: {
    AUTH(state) {
      return state.auth;
    },
  },
};

// import components
import DefaultLayout from "@/layouts/DefaultLayout.vue";

import { Form, Field, ErrorMessage } from "vee-validate";
// buttons
import ButtonLilac from "@/components/ui/button/ButtonLilac";
import ButtonPurple from "@/components/ui/button/ButtonPurple";
// register components
export const globalComponents = (app) => {
  // layouts
  app.component("DefaultLayout", DefaultLayout);
  // buttons
  app.component("ButtonLilac", ButtonLilac);
  app.component("ButtonPurple", ButtonPurple);

  app.component("Form", Form);
  app.component("Field", Field);
  app.component("ErrorMessage", ErrorMessage);
};

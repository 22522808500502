<template>
  <button
    class="text__button"
    :class="{ disabled: disabled }"
    :type="type"
    :disabled="disabled"
  >
    {{ name }}
  </button>
</template>

<script>
export default {
  name: "ButtonLilac",
  props: {
    name: { type: String, required: true },
    type: {
      type: String,
      default: "text",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  position: relative;
  border-radius: 6px;
  padding: 13px 24px;
  height: 46px;
  border: 1px solid $lilac;
  color: $lilac;
  transition: 0.1s ease-in;
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:hover:enabled {
    color: $lilac_l1;
    border: 1px solid $lilac_l1;
  }
  &:active:enabled,
  &:focus-visible:enabled {
    border: 1px solid $lilac_d1;
  }
}
</style>

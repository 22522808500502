import { $axios } from "@/plugins/axios";

import numberApi from "./_number";
import authApi from "./_auth";
import userApi from "./_user";
import generatorApi from "./_generator";
import numberVkApi from "./_number_vk";
import numberIgApi from "./_number_ig";
import payApi from "./_pay";

export const api = {
  number: numberApi($axios),
  auth: authApi($axios),
  user: userApi($axios),
  generator: generatorApi($axios),
  number_vk: numberVkApi($axios),
  number_ig: numberIgApi($axios),
  pay: payApi($axios),
};

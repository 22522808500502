import Toast, { useToast } from "vue-toastification";
import "@/assets/scss/toast.scss";

const ToastPlugin = (app) => {
  app.use(Toast, {
    // You can set your default options here
  });
  app.config.globalProperties.$toast = useToast();
};

export default { ToastPlugin, ToastForAxios: useToast() };

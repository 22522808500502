<template>
  <footer class="footer">
    <div class="footer__content text__14">
      <div class="footer-col">
        <!--        <a
          class="adaptive text__gray2"
          href="tel:+79955037330"
          style="margin: 0 0 16px"
        >
          +7(995)503-73-30
        </a>
        <a
          class="adaptive text__gray2"
          href="mailto:myrando.sup@gmail.com"
          style="margin: 0 0 16px"
        >
          myrando.sup@gmail.com
        </a>
        <router-link
          :to="{ name: 'Offer' }"
          class="text__14 text__liliac_l2 link"
          >Публичная оферта</router-link
        >-->
        <div class="social__mobile">
          <div style="margin: 0 0 16px" class="text__liliac_l2 social">
            <span>Поделиться:</span>
            <a
              href="https://vk.com/share.php?url=https://myrando.ru"
              target="_blank"
            >
              <img src="@/assets/images/svg/social/vk.svg" alt="vk" />
            </a>
          </div>
          <div class="text__liliac_l2 social">
            <span>Связаться с нами:</span>
            <a href="https://vk.com/myrando" target="_blank">
              <img src="@/assets/images/svg/social/vk.svg" alt="vk" />
            </a>
            <a href="mailto:myrando.sup@gmail.com" target="_blank">
              <img src="@/assets/images/svg/social/mail.svg" alt="vk" />
            </a>
            <a href="https://t.me/My_Rando" target="_blank">
              <img src="@/assets/images/svg/social/telegram.svg" alt="vk" />
            </a>
            <a href="https://wa.me/message/IDT5JMYZIWNXB1" target="_blank">
              <img src="@/assets/images/svg/social/whatsapp.svg" alt="vk" />
            </a>
          </div>
        </div>
      </div>
      <div class="footer-col">
        <router-link :to="{ name: 'Number' }" class="text__liliac_l2 link">
          Генератор чисел
        </router-link>
        <router-link class="text__liliac_l2 link" :to="{ name: 'Win' }">
          Выбор победителя
        </router-link>
        <router-link class="text__liliac_l2 link" :to="{ name: 'Password' }">
          Пароли
        </router-link>
        <router-link class="text__liliac_l2 link" :to="{ name: 'Ask' }">
          Ответы и предсказания
        </router-link>
        <router-link class="text__liliac_l2 link" :to="{ name: 'Question' }">
          Вопросы
        </router-link>
        <router-link class="text__liliac_l2 link" :to="{ name: 'Fact' }">
          Факты
        </router-link>
      </div>
      <div class="footer-col">
        <div class="social__content">
          <div
            style="margin: 0 0 16px"
            class="text__liliac_l2 social social__desktop"
          >
            <span>Поделиться:</span>
            <a
              href="https://vk.com/share.php?url=https://myrando.ru"
              target="_blank"
            >
              <img src="@/assets/images/svg/social/vk.svg" alt="vk" />
            </a>
          </div>
          <div class="text__liliac_l2 social social__desktop">
            <span>Связаться с нами:</span>
            <a href="https://vk.com/myrando" target="_blank">
              <img src="@/assets/images/svg/social/vk.svg" alt="vk" />
            </a>
            <a href="mailto:myrando.sup@gmail.com" target="_blank">
              <img src="@/assets/images/svg/social/mail.svg" alt="vk" />
            </a>
            <a href="https://t.me/My_Rando" target="_blank">
              <img src="@/assets/images/svg/social/telegram.svg" alt="vk" />
            </a>
            <a href="https://wa.me/message/IDT5JMYZIWNXB1" target="_blank">
              <img src="@/assets/images/svg/social/whatsapp.svg" alt="vk" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "TheFooter",
};
</script>

<style lang="scss" scoped>
$mobileWidth: 768px;

.footer {
  background: $blue;
}

.footer__content {
  padding: 44px 16px;
  @include disflex(flex, space-between, center);
  gap: 218px;
  @media screen and (max-width: 1920px) {
    @include adaptive-elem(gap, 218, 28);
    @include adaptive-value(padding, 44 32, 16, 44 32, 16);
  }
  @media screen and (max-width: $mobileWidth) {
    flex-direction: column;
    align-items: stretch;
    gap: 28px;
  }
}

.footer__start a {
  margin: 0 0 16px;
  &:last-child {
    margin: 0;
  }
}

.footer-col {
  a {
    display: block;
    width: fit-content;
  }
  .link {
    padding: 0 0 1px;
    border-bottom: 1px solid $lilac_l2;
  }

  &:first-of-type {
    @media screen and (max-width: $mobileWidth) {
      order: 3;
      padding: 28px 0 0;
      border-top: 1px solid $gray4;
    }
    div {
      margin: 0 0 8px;
      &:last-of-type {
        margin: 0 0 24px;
      }
    }
  }
  &:nth-of-type(2) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px 16px;
    @media screen and (max-width: 976px) {
      grid-template-columns: 1fr;
    }
    @media screen and (max-width: $mobileWidth) {
      order: 1;
    }
    a {
      display: block;
      width: max-content;
    }
  }
  &:last-of-type {
    @media screen and (max-width: $mobileWidth) {
      order: 2;
    }
  }
}

.social {
  @include disflex(flex, stretch, center);
  white-space: nowrap;
  gap: 20px;
  @media screen and (max-width: $mobileWidth) {
    justify-content: stretch;
  }

  img {
    width: 24px;
  }
}

.social__desktop {
  @media screen and (max-width: $mobileWidth) {
    display: none;
  }
}

.social__mobile {
  display: none;
  @media screen and (max-width: $mobileWidth) {
    display: block;
    padding: 28px 0 0;
    margin: 28px 0 0 !important;
    border-top: 1px solid $gray4;
    .social {
      margin: 0 !important;
    }
  }
}
</style>

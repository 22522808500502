import { mapActions } from "vuex";

export default {
  mounted() {
    this.checkWidth();
    window.addEventListener("resize", this.checkWidth);
  },
  unmounted() {
    window.removeEventListener("resize", this.checkWidth);
  },
  methods: {
    ...mapActions(["CHECK__WIDTH"]),
    checkWidth() {
      window.innerWidth <= 976
        ? this.CHECK__WIDTH(false)
        : this.CHECK__WIDTH(true);
    },
  },
};

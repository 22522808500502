export default ($axios) => ({
  async postPay() {
    try {
      const { data } = await $axios.get("payment/pay_form");
      return data;
    } catch (e) {
      return e;
    }
  },
});

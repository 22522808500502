import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { api } from "@/api";
import { globalComponents } from "@/plugins/globalComponents";
import { globalYup } from "@/plugins/yup";
import Maska from "maska";
import Toast from "@/plugins/toast";
import VueCookies from "vue3-cookies";
import { Moment } from "@/plugins/moment";

const app = createApp(App);
app.use(store);
app.use(router);
//add api
app.config.globalProperties.$api = api;
store.$api = api;
// plugins
globalComponents(app);
globalYup(app);
app.use(Maska);
Toast.ToastPlugin(app);
app.use(VueCookies, {
  expireTimes: "Э0d",
  path: "/",
  domain: "",
  secure: true,
  sameSite: "None",
});
Moment(app);
// init
app.mount("#app");

// export const toast = app.$toast;

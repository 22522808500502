export default ($axios) => ({
  async postGeneratorNumber({
    count: e = 1,
    from: a = 0,
    to: n = 50,
    excludeNumbers: c = [],
  }) {
    const s = await $axios.post("generator/number", {
      from: a,
      to: n,
      count: e,
      excludeNumbers: c,
    });
    return s.response && 401 === s.response.status
      ? { message: "401" }
      : s.data;
  },
  async postGeneratorVk({
    type_post: e,
    link: a = "",
    option: n = "comment",
    extra_option_list: c = [],
  }) {
    try {
      const s = await $axios.post("generator/vk", {
        link: a,
        option: n,
        extra_option_list: c,
        type_post: e,
      });
      return s.data ? s.data : s.response.data;
    } catch (s) {
      return s;
    }
  },
  async postGeneratorIg({
    link = "",
    option = "comment",
    extra_option_list = [],
  }) {
    try {
      const response = await $axios.post("generator/ig", {
        link,
        option,
        extra_option_list,
      });

      if (response.data) return response.data;
      return response.response.data;
    } catch (e) {
      return e;
    }
  },
  async startPostParseIg({
    option = "like",
    extra_option_list = [],
    media_id = null,
  }) {
    try {
      const response = await $axios.post("generator/ig-start-post-parse", {
        media_id,
        option,
        extra_option_list,
      });

      if (response.data) return response.data;
      return response.response.data;
    } catch (e) {
      return e;
    }
  },
  async startAccountInfoParse({ username = null }) {
    try {
      const response = await $axios.post("generator/ig-account-info-parse", {
        username,
      });

      if (response.data) return response.data;
      return response.response.data;
    } catch (e) {
      return e;
    }
  },
  async startAccountParseIg({ username = null }) {
    try {
      const response = await $axios.post("generator/ig-start-account-parse", {
        username,
      });

      if (response.data) return response.data;
      return response.response.data;
    } catch (e) {
      return e;
    }
  },
  async startAccountWinner({ username = null, ig_user_id = null }) {
    try {
      const response = await $axios.post("generator/ig-random-page", {
        username,
        ig_user_id,
      });

      if (response.data) return response.data;
      return response.response.data;
    } catch (e) {
      return e;
    }
  },
  async getInfoPostByLink({ link = "" }) {
    try {
      const response = await $axios.post("generator/ig-get-info-post", {
        link,
      });

      if (response.data) return response.data;
      return response.response.data;
    } catch (e) {
      return e;
    }
  },
  async getRandomPostWinner({
    option = "like",
    extra_option_list = [],
    media_id = null,
  }) {
    try {
      const response = await $axios.post("generator/ig-random-post", {
        media_id,
        option,
        extra_option_list,
      });

      if (response.data) return response.data;
      return response.response.data;
    } catch (e) {
      return e;
    }
  },
});

<template>
  <div class="modal" :class="{ show: true }" @click.stop="$emit('close')">
    <transition name="modal">
      <div v-if="load" class="modal__content" @click.stop>
        <div class="modal__header">
          <div class="text__16 text__gray4">Вход в личный кабинет</div>
          <button class="modal__header__button" @click.prevent="$emit('close')">
            <img src="@/assets/images/svg/close.svg" alt="" />
          </button>
        </div>
        <Form
          v-show="formLoginShow"
          @submit="onSubmit"
          :validation-schema="formLogin"
        >
          <div class="modal__body">
            <IBase name="email" type="text" placeholder="Введите email" />
            <!--<IBase name="username" type="text" placeholder="Введите никнейм" />-->
          </div>
          <div class="modal__footer">
            <div class="modal__reg">
              <ButtonLilac name="Получить код" style="width: 100%" />
              <div
                class="text__14 adaptive text__black"
                style="margin: 12px 0 0"
              >
                Вводя email, ты соглашаешься с Политикой Конфиденциальности и
                даешь Согласие на обработку персональных данных
              </div>
            </div>
          </div>
        </Form>
        <div v-show="!formLoginShow">
          <div class="modal__body">
            <div class="text__14 adaptive text__black" style="margin: 0 0 12px">
              Вам отправлен код на почту:
              <span class="text__liliac">{{ this.user_email }}</span>
            </div>
            <IBase
              v-if="showInput"
              name="code"
              type="text"
              placeholder="Введите код"
              :maska="'####'"
              @eventInput="eventInputEntry"
            />
          </div>
          <div class="modal__footer"></div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import IBase from "@/components/ui/input/IBase";
import { mapActions } from "vuex";

export default {
  name: "MLogin",
  emits: ["close"],
  components: { IBase },
  data() {
    const formLogin = this.$yup.object().shape({
      email: this.$yup.string().email().required(),
      // username: this.$yup.string().required(),
    });
    return {
      formLogin,
      code: 0,
      load: false,
      formLoginShow: true,
      user_email: "",
      showInput: true,
    };
  },
  mounted() {
    this.load = true;
    window.addEventListener("keyup", this.closeKeyEsc);
  },
  unmounted() {
    window.removeEventListener("keyup", this.closeKeyEsc);
  },
  methods: {
    ...mapActions(["LOGIN", "LOGOUT"]),
    async eventInputEntry(values) {
      if (values.length >= 4) {
        const data = await this.$api.auth.confirmCode({
          email: this.user_email,
          code: values,
        });
        if (data) {
          // this.$cookies.set("refresh-token", data.token);
          // const refreshToken = await this.$api.auth.refreshToken(data.token);
          await this.LOGIN(data.token);
          this.$toast("Успешная авторизация");
          this.$emit("close");
        } else {
          this.$toast("Неверно введен код");
          this.showInput = false;
          await this.$nextTick(() => {
            this.showInput = true;
          });
        }
      }
    },
    closeKeyEsc(event) {
      if (event.keyCode === 27) this.$emit("close");
    },
    async onSubmit({ email }) {
      const data = await this.$api.auth.login(email.toLowerCase());
      if (data) {
        this.user_email = data.email;
        this.formLoginShow = false;
      } else {
        this.$toast("Непредвиденная ошибка");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal__reg {
  @media screen and (max-width: 976px) {
    margin: 16px 0 0;
  }
}
</style>

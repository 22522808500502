<template>
  <div>
    <div v-if="!AUTH">
      <ButtonLilac name="Войти" @click="SHOW_OR_HIDE_MODAL_LOGIN(true)" />
    </div>
    <div v-else class="account">
      <span class="account__premium text__12" v-if="USER?.premium"
        >Premium</span
      >
      <button
        v-if="USER?.is_superadmin"
        style="margin: 0 8px 0"
        class="text__gray4"
        @click="$router.push({ name: 'Admin' })"
      >
        Войти в админку
      </button>
      <button @click="LOGOUT()">
        <img src="@/assets/images/svg/exit.svg" alt="exit" />
      </button>
    </div>
    <!--    <a v-if="AUTH && !vkAuth" :href="urlVkAuth" class="auth text__gray3"-->
    <!--      >Авторизация <span><img src="@/assets/images/auth/vk.png" alt="" /></span-->
    <!--    ></a>-->
    <!--    <button v-if="AUTH && vkAuth" @click="logoutVK">Выйти из Vk</button>-->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "HPersonal",
  data() {
    return {
      vkAuth: false,
    };
  },
  created() {
    this.checkAuthVk();
  },
  computed: {
    ...mapGetters(["AUTH", "USER"]),
  },
  methods: {
    ...mapActions(["LOGOUT", "SHOW_OR_HIDE_MODAL_LOGIN"]),
    checkAuthVk() {
      return this.$cookies.get("vk-auth")
        ? (this.vkAuth = true)
        : (this.vkAuth = false);
    },
    logoutVK() {
      this.$cookies.remove("vk-auth");
      this.checkAuthVk();
    },
  },
};
</script>

<style lang="scss" scoped>
.account {
  display: flex;
  align-items: center;
  button,
  span {
    display: block;
  }
  &__premium {
    position: relative;
    background: $purpul_d1;
    border-radius: 3px;
    padding: 2px 5px 2px 4px;
    color: #fff;
  }
}

.auth {
  @include disflex(flex, stretch, center);
  span {
    margin: 0 0 0 4px;
    background: #4a76a8;
    @include disflex(flex, center, center);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    img {
      width: 100%;
    }
  }
}
</style>

export default {
  state: {
    modal_login: false,
  },
  actions: {
    SHOW_OR_HIDE_MODAL_LOGIN({ commit }, boolean) {
      commit("UPDATE_STATE_MODAL_LOGIN", boolean);
    },
  },
  mutations: {
    UPDATE_STATE_MODAL_LOGIN(state, boolean) {
      state.modal_login = boolean;
    },
  },
  getters: {
    MODAL_LOGIN(state) {
      return state.modal_login;
    },
  },
};

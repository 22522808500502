export default ($axios) => ({
  async login(email) {
    try {
      const { data } = await $axios.post("auth/login", { email });
      return data;
    } catch (e) {
      return e;
    }
  },
  async confirmCode({ email, code }) {
    try {
      const formData = new FormData();
      formData.append("email", email);
      formData.append("code", code);
      const { data } = await $axios.post("auth/confirm-code", formData);
      return data;
    } catch (e) {
      return e;
    }
  },
  async refreshToken(token) {
    try {
      const { data } = await $axios.post("auth/refresh-token", {
        "refresh-token": token,
      });
      return data;
    } catch (e) {
      return e;
    }
  },
  async logout() {
    try {
      const { data } = await $axios.post("auth/logout", {});
      return data;
    } catch (e) {
      return e;
    }
  },
  async postAuthLinkVk() {
    try {
      const { data } = await $axios.get("auth/vk-login", {});
      return data;
    } catch (e) {
      return e;
    }
  },
  async postAuthLinkIG() {
    try {
      const { data } = await $axios.get("auth/ig-link", {});
      return data;
    } catch (e) {
      return e;
    }
  },
  async postAuthVk(code) {
    try {
      const { data } = await $axios.get("auth/vk", { params: { code } });
      return data;
    } catch (e) {
      return e;
    }
  },
  async postAuthIg(code) {
    try {
      const { data } = await $axios.get("auth/ig", { params: { code } });
      return data;
    } catch (e) {
      return e;
    }
  },
  async logoutVk() {
    try {
      const { data } = await $axios.get("auth/vk-logout");
      return data;
    } catch (e) {
      return e;
    }
  },
  async logoutIg() {
    try {
      const { data } = await $axios.get("auth/ig-logout");
      return data;
    } catch (e) {
      return e;
    }
  },
});

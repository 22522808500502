export default {
  state: {
    user: {},
    user_vk: {},
    user_ig: {},
  },
  actions: {
    async GET_USER_ME({ commit }) {
      const data = await this.$api.user.getUserMe();
      commit("UPDATE_STATE_USER", data);
    },
    async GET_USER_ME_VK({ commit }) {
      try {
        const data = await this.$api.user.getUserMeVk();
        commit("UPDATE_STATE_USER_VK", data);
      } catch (e) {
        console.log(e);
      }
    },
    async GET_USER_ME_IG({ commit }) {
      const data = await this.$api.user.getUserMeIg();
      commit("UPDATE_STATE_USER_IG", data);
    },
  },
  mutations: {
    UPDATE_STATE_USER(state, payload) {
      state.user = payload;
    },
    UPDATE_STATE_USER_VK(state, payload) {
      state.user_vk = payload;
    },
    UPDATE_STATE_USER_IG(state, payload) {
      state.user_ig = payload;
    },
  },
  getters: {
    USER(state) {
      return state.user;
    },
    USER_VK(state) {
      return state.user_vk;
    },
    USER_IG(state) {
      return state.user_ig;
    },
  },
};
